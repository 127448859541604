import { INSPECTION as ActionTypes } from '../actionTypes';


const initialState = {
    inspection: {
        inspectionId: 0,
        buildingId: 0,
        customerId: 0,
        status: '',
        statusId: 0,
        proposalSentDate: '',
        proposalReceivedDate: '',
        dobNotifyDate: '',
        dobNowJobNumber: '',
        purchaseOrder: '',
        proposalYearEnd: '',
        pricePerDevice: '',
        isPeriodic: 0,
        witnessFee: '',
        isCertificateManagement: false,
        aocTrackingId: 0,
        aocTrackingFee: '',
        qceTrackingId: 0,
        qceFee: '',
        year: '',
        hasPrePayFilingFee: false,
        expeditingFees: '',
        proposalId: '',
        proposalLink: '',
        misc: '',
        elevatorCompanyId: 0,
        testingCompanyId: 0,
        customer: {
            customerName: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phoneFormatted: '',
            faxFormatted: '',
            poRequired: false,
            email: '',
            emailCC: '',
            complianceContact: '',
            salesforceId: ''
        },
        building: {
            agentId: 0,
            activeDevices: 0,
            address: '',
            buildingName: '',
            block: '',
            lot: '',
            coiReceived: 0,
            borough: '',
            zip: '',
            hood: '',
            taxExempt: 0,
            coiExpDate: '',
            salesforceId: '',
            superName: '',
            superEmail: '',
            superPhoneFormatted: ''
        },
        agent: {
            firstLast: '',
            phoneFormatted: '',
            email: ''
        },
        notes: [],
        inspLines: [],

    },
    // inspectionLine: {
    //     inspectionLineId: 0,
    //     defs: []
    // }
};



export const inspectionReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_INSPECTION:

            return {
                ...state,
                inspection: action.payload
            };

        case ActionTypes.CLEAR_INSPECTION:

            return {
                ...state,
                inspection: initialState.inspection
            };

        default:
            return state;
    };
};