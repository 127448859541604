const changeLog = [
    {
        "Version": "1.0.14.223",
        "Date": "10.31.24",
        "Notes": [
            "Top menu remove admin if not admin"
        ]
    },
    {
        "Version": "1.0.14.222",
        "Date": "10.30.24",
        "Notes": [
            "Admin Proposal v2 work",
        ]
    },
    {
        "Version": "1.0.14.221",
        "Date": "10.17.24",
        "Notes": [
            "Admin Proposal - only show pricing on proposal details if expiring view is selected",
        ]
    },
    {
        "Version": "1.0.14.220",
        "Date": "7.22.24",
        "Notes": [
            "Admin users - office work - continue",
        ]
    },
    {
        "Version": "1.0.14.219",
        "Date": "6.25.24",
        "Notes": [
            "Admin users - office work - more work to do",
        ]
    },
    {
        "Version": "1.0.14.218",
        "Date": "6.4.24",
        "Notes": [
            "Insp file uploads - insp mobile image uploads",
        ]
    },
    {
        "Version": "1.0.14.217",
        "Date": "5.23.24",
        "Notes": [
            "Added insp counts by insp type to inspection dashboard",
        ]
    },
    {
        "Version": "1.0.14.216",
        "Date": "5.21.24",
        "Notes": [
            "Redeployed cert management items - nothing new just yet",
        ]
    },
    {
        "Version": "1.0.14.215",
        "Date": "5.17.24",
        "Notes": [
            "Added all, cat and periodic to aoc insp dashboard details",
        ]
    },
    {
        "Version": "1.0.14.214",
        "Date": "5.17.24",
        "Notes": [
            "Device hydro cat 5 issue"
        ]
    },
    {
        "Version": "1.0.14.213",
        "Date": "5.15.24",
        "Notes": [
            "Added aoc insp dashboard totals details view - can change by year"
        ]
    },
    {
        "Version": "1.0.14.212",
        "Date": "5.9.24",
        "Notes": [
            "Added cert management elevator results section"
        ]
    },
    {
        "Version": "1.0.14.211",
        "Date": "5.8.24",
        "Notes": [
            "Added work and personal phone for witness modal on inspection view"
        ]
    },
    {
        "Version": "1.0.14.210",
        "Date": "5.7.24",
        "Notes": [
            "Added weekly view and day nav scheduler back to current week and current day"
        ]
    },
    {
        "Version": "1.0.14.209",
        "Date": "5.7.24",
        "Notes": [
            "Added inspection lines by witness report"
        ]
    },
    {
        "Version": "1.0.14.208",
        "Date": "5.3.24",
        "Notes": [
            "Added inspection lines by type report"
        ]
    },
    {
        "Version": "1.0.14.207",
        "Date": "5.3.24",
        "Notes": [
            "Inspection witness modal"
        ]
    },
    {
        "Version": "1.0.14.206",
        "Date": "5.1.24",
        "Notes": [
            "Cert Management Work"
        ]
    },
    {
        "Version": "1.0.14.205",
        "Date": "4.15.24",
        "Notes": [
            "Inspection Elevator inspection report"
        ]
    },
    {
        "Version": "1.0.14.204",
        "Date": "4.4.24",
        "Notes": [
            "Move cert insp fuctionality to cert management container",
            "Pass grouped insp state to cert left view",
            "Add filtered insp view for cert management is true",
            "Refactor cert managament insert inspection",
            "Can nav back to customer cert management view from insp",
        ]
    },
    {
        "Version": "1.0.14.203",
        "Date": "3.28.24",
        "Notes": [
            "Add insp insert modal to cert management view in customer module",
            "Add state to customer inspection results list",
        ]
    },
    {
        "Version": "1.0.14.202",
        "Date": "3.27.24",
        "Notes": [
            "Update license transfer date work on insp email and upcoming inspection dashboard details list",
        ]
    },
    {
        "Version": "1.0.14.201",
        "Date": "3.14.24",
        "Notes": [
            "BUG - insp header readonly elevator link didn't have a 'to#'.  was causing error when modal is launched",
        ]
    },
    {
        "Version": "1.0.14.200",
        "Date": "3.12.24",
        "Notes": [
            "Added 2024 to the filter for inspector company image",
        ]
    },
    {
        "Version": "1.0.14.199",
        "Date": "3.11.24",
        "Notes": [
            "Added inspector company logo to insp line and some dashboard items",
        ]
    },
    {
        "Version": "1.0.14.198",
        "Date": "3.1.24",
        "Notes": [
            "QCE status details complete",
        ]
    },
    {
        "Version": "1.0.14.197",
        "Date": "2.15.24",
        "Notes": [
            "QCE Dashboard item started",
        ]
    },
    {
        "Version": "1.0.14.196",
        "Date": "2.22.24",
        "Notes": [
            "Completed Customer Analytics for inspection summary and elevator cat results",
        ]
    },
    {
        "Version": "1.0.14.195",
        "Date": "2.15.24",
        "Notes": [
            "Added is periodic to customer insp history"
        ]
    },
    {
        "Version": "1.0.14.194",
        "Date": "2.14.24",
        "Notes": [
            "Customer Inspection history now shows all inspections for a customer by year"
        ]
    },
    {
        "Version": "1.0.13.193",
        "Date": "2.12.24",
        "Notes": [
            "Device - NR Hydro Last Cat 5 fix"
        ]
    },
    {
        "Version": "1.0.13.192",
        "Date": "2.7.24",
        "Notes": [
            "JIRA 158 - HOTFIX: refactored insp line check for salesforce building and customer link",
            "JIRA 158 - HOTFIX: refactored invoice view for salesforce building and customer link",
        ]
    },
    {
        "Version": "1.0.13.191",
        "Date": "2.6.24",
        "Notes": [
            "HOTFIX: bug on LineDetailsEdit.js - same to same cat 1 result was changing value to 0",
        ]
    },
    {
        "Version": "1.0.13.190",
        "Date": "1.31.24",
        "Notes": [
            "Update target api version to 139",
        ]
    },
    {
        "Version": "1.0.13.189",
        "Date": "1.29.24",
        "Notes": [
            "Fix insp line invoice only on line complete"
        ]
    },
    {
        "Version": "1.0.13.188",
        "Date": "1.28.24",
        "Notes": [
            "Run qce status change on insp line insert",
            "Fix qce status badge from insp details view",
        ]
    },
    {
        "Version": "1.0.13.187",
        "Date": "1.27.24",
        "Notes": [
            "Added insp line qce status update feature",
            "Users can manually update qce status",
        ]
    },
    {
        "Version": "1.0.13.186",
        "Date": "1.18.24",
        "Notes": [
            "HOTFIX - device details not using the new salesforce component for customer and buildings",
        ]
    },
    {
        "Version": "1.0.13.185",
        "Date": "1.16.24",
        "Notes": [
            "Begin QCE work on inspection",
        ]
    },
    {
        "Version": "1.0.13.184",
        "Date": "1.12.24",
        "Notes": [
            "Continue user module work",
            "Can send Weekly Inspection Email from Scheduler",
            "Update inspection super bug",
            "Added QCE to scheduler UI"
        ]
    },
    {
        "Version": "1.0.13.183",
        "Date": "1.3.24",
        "Notes": [
            "Fixed useEffect Countdown totals not appearing"
        ]
    },
    {
        "Version": "1.0.12.182",
        "Date": "12.29.23",
        "Notes": [
            "Allow user to navigate to inspection from the invoice screen",
            "On inspection exit, navigate back to invoice screen"
        ]
    },
    {
        "Version": "1.0.12.181",
        "Date": "12.29.23",
        "Notes": [
            "Reload inspection search results on inspection exit if nav back is the inspection search screen only"
        ]
    },
    {
        "Version": "1.0.12.180",
        "Date": "12.28.23",
        "Notes": [
            "Allow users to change the checked in status of a proposal"
        ]
    },
    {
        "Version": "1.0.12.179",
        "Date": "12.26.23",
        "Notes": [
            "Inspection Dashboard refactor - useEffect cleanup for charts",
            "Added scheduler email option"
        ]
    },
    {
        "Version": "12.19.23.178",
        "Notes": [
            "Added invoice push error modal"
        ]
    },
    {
        "Version": "12.18.23.177",
        "Notes": [
            "Can view invoices, buildings and customers via link in salesforce",
            "Invoice batch ui work - added links, fixed cols",
            "Updated Salesforce Alert component"
        ]
    },
    {
        "Version": "12.18.23.176",
        "Notes": [
            "Inspection invoice work"
        ]
    },
    {
        "Version": "12.7.23.175",
        "Notes": [
            "Inspection Dashboard totals details view for totals section"
        ]
    },
    {
        "Version": "12.5.23.174",
        "Notes": [
            "User can manage own inspection notes",
            "Building - super email verification bug"
        ]
    },
    {
        "Version": "11.28.23.173",
        "Notes": [
            "Client Status Report"
        ]
    },
    {
        "Version": "11.20.23.172",
        "Notes": [
            "Outstanding Field Work Report - date fix"
        ]
    },
    {
        "Version": "11.17.23.171",
        "Notes": [
            "Outstanding Field Work Report - date fix"
        ]
    },
    {
        "Version": "11.16.23.170",
        "Notes": [
            "Outstanding Field Work Report"
        ]
    },
    {
        "Version": "11.15.23.169",
        "Notes": [
            "Inspection Cancel"
        ]
    },
    {
        "Version": "11.14.23.168",
        "Notes": [
            "AOC Report update",
            "Proposal Send from Proposal list"
        ]
    },
    {
        "Version": "11.13.23.167",
        "Notes": [
            "AOC Report in inspections"
        ]
    },
    {
        "Version": "11.5.23.166",
        "Notes": [
            "Device NR Hydro fix "
        ]
    },
    {
        "Version": "11.3.23.165",
        "Notes": [
            "HOTFIX - Customer zip "
        ]
    },
    {
        "Version": "11.2.23.165",
        "Notes": [
            "Delete proposal line",
            "Cancel and uncancel inspection",
            "add cancel note",
            "fix insp date if not prop sent prop rec",
            "can invoice not invoice inspection"
        ]
    },
    {
        "Version": "10.24.23.164",
        "Notes": [
            "Insert a proposal batch",
            "Update insp reports to start on monday"
        ]
    },
    {
        "Version": "10.20.23.163",
        "Notes": [
            "Cancel inspection",
            "Insp details periodic cat labels on dates"
        ]
    },
    {
        "Version": "10.18.23.162",
        "Notes": [
            "Sent multiple proposals",
            "Added proposal delete"
        ]
    },
    {
        "Version": "10.5.23.161",
        "Notes": [
            "Send proposal refactor",
            "Insp dashboard mobile list - redirect to inspection",
            "Proposal List - update ui"
        ]
    },
    {
        "Version": "10.4.23.160",
        "Notes": [
            "Proposal Send update"
        ]
    },
    {
        "Version": "9.26.23.159",
        "Notes": [
            "Admin module setup",
            "Proposal admin feature",
            "Can run proposals",
            "Can preview proposal",
            "Can send proposal"
        ]
    },
    {
        "Version": "9.14.23.158",
        "Notes": [
            "Can update device from inspection"
        ]
    },
    {
        "Version": "9.14.23.157",
        "Notes": [
            "Proposal start",
            "Device details modal in inspection lines"
        ]
    },
    {
        "Version": "8.29.23.156",
        "Notes": [
            "Delete insp line"
        ]
    },
    {
        "Version": "8.23.23.155",
        "Notes": [
            "Insp header details - proposal through year is now required",
            "Bug - fixed insp line new device modal is now correctly showing only active devices"
        ]
    },
    {
        "Version": "8.22.23.154",
        "Notes": [
            "Can insert a new inspection",
            "Update device cat 5 due date based on last cat 5 inspection"
        ]
    },
    {
        "Version": "8.16.23.153",
        "Notes": [
            "Clear new inspe line modal details on cancel and on insert"
        ]
    },
    {
        "Version": "8.14.23.152",
        "Notes": [
            "Fix aoc extension bug",
            "Can insert inspection line",
            "New inspection modal",
            "Redeploy"
        ]
    },
    {
        "Version": "8.10.23.151",
        "Notes": [
            "Deploy from new mac"
        ]
    },
    {
        "Version": "8.9.23.150",
        "Notes": [
            "Reorder line details edit view",
            "Allow user to edit and clear inspection images"
        ]
    },
    {
        "Version": "8.4.23.149",
        "Notes": [
            "Add mobile images to ui",
            "Add backend upload service",
            "Refactor inspection id search",
            "Add close and not save to front end",
            "Add local timezone visibility to back end",
            "Update image part id for mobile images"
        ]
    },
    {
        "Version": "7.31.23.148",
        "Notes": [
            "Added elv3 comments to insp line",
            "Refactor double book check only on not complete and not cat5 - added useEffect to only check when date, time or inspector change"
        ]
    },
    {
        "Version": "7.27.23.147",
        "Notes": [
            "Added aoc extension 1 and 2 ui feature",
            "Api updated to calculate new aoc extension values"
        ]
    },
    {
        "Version": "7.25.23.146",
        "Notes": [
            "Defs required if unsat",
            "Fixed bug on sat where message was appearing"
        ]
    },
    {
        "Version": "7.25.23.145",
        "Notes": [
            "Hide aoc section unless complete",
            "Hide defs section unless cat1results = unsat",
            "do not allow to complete if unsat and no defs",
            "If line is cat 1 or periodic and complete, allow to toggle between sat and unsat",
            "If line results goes from unsat to sat, delete all defs"
        ]
    },
    {
        "Version": "7.20.23.144",
        "Notes": [
            "Refactor line complete"
        ]
    },
    {
        "Version": "7.17.23.143",
        "Notes": [
            "Fix periodic not completing"
        ]
    },
    {
        "Version": "7.17.23.142",
        "Notes": [
            "Can complete inspection line"
        ]
    },
    {
        "Version": "7.6.23.141",
        "Notes": [
            "Hide inspection tabs top until ready",
            "Update the cat 5 due date in an individual line only if there is a cat5 item",
            "Create admin override modal and api service to validate",
            "email inspection"
        ]
    },
    {
        "Version": "6.1.23.140",
        "Notes": [
            "Can insert def to line",
            "Can update def on line",
            "Can delete def from line",
            "Added validation - can only update and insert if all 3 items are selected"
        ]
    },
    {
        "Version": "5.31.23.139",
        "Notes": [
            "Fixed customer and elevator load fetch call - was clearing redux when call was resolved causing no data to display",
            "Updated api get defs by insp line - don't show where deleted = 1",
            "Disable def add button if results are not unsat"
        ]
    },
    {
        "Version": "5.30.23.138",
        "Notes": [
            "Inspection agent replace",
            "Inspection note add",
            "dob def ui work"
        ]
    },
    {
        "Version": "5.23.23.137",
        "Notes": [
            "Add loading to resource dropdowns in scheduler"
        ]
    },
    {
        "Version": "5.23.23.136",
        "Notes": [
            "Schedule resources hotfix"
        ]
    },
    {
        "Version": "5.22.23.135",
        "Notes": [
            "Schedule check report",
            "Inspection report module created",
            "Api work to return insp reports",
            "Inspection transfer - clear on transfer, show loader"
        ]
    },
    {
        "Version": "5.16.23.134",
        "Notes": [
            "Inspection customer transfer start"
        ]
    },
    {
        "Version": "5.15.23.133",
        "Notes": [
            "Building section update to display different building from inspection and building module"
        ]
    },
    {
        "Version": "5.12.23.132",
        "Notes": [
            "Customer section update to display different customer from inspection and customer module",
            "Updated the customer readonly modal",
            "Created the insp header details modal",
            "Added an update action",
            "Added api service call to update inspection",
            "Added loading feature on inspection update"
        ]
    },
    {
        "Version": "5.1.23.131",
        "Notes": [
            "Added inspTypeList items into scheduler reducer"
        ]
    },
    {
        "Version": "4.27.23.130",
        "Notes": [
            "Added shared modal loader",
            "Added inspection backUrl redirect"
        ]
    },
    {
        "Version": "4.26.23.129",
        "Notes": [
            "Added timeline to notes",
            "Added notes count to notes section"
        ]
    },
    {
        "Version": "4.26.23.128",
        "Notes": [
            "Make inspection email links work",
            "Add proposal external id and redirect",
            "Change colors on invoice status",
            "Change colors on last cat 1 last periodic",
            "Change colors on insp line complete",
            "Add customer salesforce get open invoices",
            "Add building salesforce get open invoices",
            "Refactor salesfore alerts"
        ]
    },
    {
        "Version": "4.26.23.127",
        "Notes": [
            "Add inspection notes scroll bar - refactor notes section",
            "Add inspection type to inspection search",
            "Change complete view on single insp line",
            "Add background color to cat results red and green"
        ]
    },
    {
        "Version": "4.25.23.126",
        "Notes": [
            "Inspection lines work - fill data in - cat5 not applicable",
            "Inspection header yes no work",
            "Inspection fixed note bug not displaying on no not"
        ]
    },
    {
        "Version": "4.24.23.125",
        "Notes": [
            "Inspection search view",
            "Inspection navigation refactor",
            "Inspection tabs start",
            "Inspection lines start"
        ]
    },
    {
        "Version": "3.31.23.124",
        "Notes": [
            "Can send transfer email for building"
        ]
    },
    {
        "Version": "3.24.23.123",
        "Notes": [
            "Added buildings agent tab"
        ]
    },
    {
        "Version": "3.24.23.122",
        "Notes": [
            "Can transfer agent to another customer",
            "On building details edit, agent dropdown now using firstName and lastName composite on the fly instead of using the LastFirst field from database"
        ]
    },
    {
        "Version": "3.23.23.121",
        "Notes": [
            "Disable transfer button for building on click until it is complete",
            "Prevent user from transferring building to the same customer"
        ]
    },
    {
        "Version": "3.21.23.120",
        "Notes": [
            "Transfer building and devices ui",
            "Transfer deviceApi work"
        ]
    },
    {
        "Version": "3.17.23.119",
        "Notes": [
            "Reload agent search list when single agent is updated",
            "Started frontend building transfer",
            "Created shared customer search and customer search results components"
        ]
    },
    {
        "Version": "3.16.23.118",
        "Notes": [
            "Added license check for company contact",
            "Can insert contact",
            "Refactor contact update"
        ]
    },
    {
        "Version": "3.16.23.117",
        "Notes": [
            "Refactor company contact container",
            "Company contact edit and readonly views"
        ]
    },
    {
        "Version": "3.7.23.116",
        "Notes": [
            "Refactor devices and module",
            "Added elevator module insert, edit, list and search"
        ]
    },
    {
        "Version": "3.1.23.115",
        "Notes": [
            "Update building and agent list to show shared customerlistcell component",
            "Update PageTitleDual component to show title and id side by side"
        ]
    },
    {
        "Version": "3.1.23.114",
        "Notes": [
            "Update building salesforce open invoice"
        ]
    },
    {
        "Version": "3.1.23.113",
        "Notes": [
            "Update device container in buildings and customers to get buildings on device insert"
        ]
    },
    {
        "Version": "2.27.23.112",
        "Notes": [
            "Can insert device from customer module"
        ]
    },
    {
        "Version": "2.27.23.112",
        "Notes": [
            "Agent module work",
            "Customer device work",
            "Can update device"
        ]
    },
    {
        "Version": "2.10.23.111",
        "Notes": [
            "Added loader to customer search on customer select",
            "Added api call to get customer salesforce single",
            "Added OpenInvoiceTotal to customerInit",
            "Added salesforce section to customer readonly view",
            "Add refresh button to salesforce customer section to get open invoice total on demand"
        ]
    },
    {
        "Version": "2.9.23.110",
        "Notes": [
            "Building search will do a strict begins with on address or corp name",
            "Customer search now has building count and device count"
        ]
    },
    {
        "Version": "2.8.23.109",
        "Notes": [
            "Building insert",
            "Customer insert"
        ]
    },
    {
        "Version": "1.30.23.108",
        "Notes": [
            "Building search loader",
            "Added memo hook"
        ]
    },
    {
        "Version": "1.27.23.107",
        "Notes": [
            "Building module work",
            "Refactor building search",
            "Refactor customer search"
        ]
    },
    {
        "Version": "1.11.23.106",
        "Notes": [
            "Updated countdown year for inspections"
        ]
    },
    {
        "Version": "1.11.23.105",
        "Notes": [
            "Added year dropdown for inspection pending search"
        ]
    },
    {
        "Version": "1.11.23.104",
        "Notes": [
            "Hide Toggle on scheduler when category and periodic drop down are selected",
            "Fix customer update screen not changing back to read only view"
        ]
    },
    {
        "Version": "1.11.23.103",
        "Notes": [
            "Updated salesforce customer work",
            "Added date toggle to the scheduler"
        ]
    }
];


export { changeLog };